import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MenuDesktopPlain from './MenuDesktopDasa'

const MenuDesktopDasa = props => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeHeader {
        sidebars {
          widgets {
            value {
              items {
                title
                description
                url
                wordpress_children {
                  object_id
                  title
                  description
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const menus = data.wordpressGeneoneThemeHeader.sidebars[0].widgets[0].value.items

  return (
    <MenuDesktopPlain menus={menus} location={props} />
  )

}

export default MenuDesktopDasa
