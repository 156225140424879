import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VerticalSubmenu from './verticalSubmenu'

import * as S from './styled'

const SubmenuItem = ({ submenu }) => {
  const hasSubmenu = submenu.submenus && submenu.submenus.length
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const [descriptionOpen, setDescriptionOpen] = useState(false)

  return (
    <S.SubMenuItemsDropdownDasa
      key={`submenu_${submenu.title}`}
      onMouseOver={() => {
        setSubmenuOpen(true)
        setDescriptionOpen(true)
      }}
      onMouseLeave={() => {
        setSubmenuOpen(false)
        setDescriptionOpen(false)
      }}
      to={submenu.url}
    >
      <S.ItemLinkDropdownDasa
      >
        {submenu.title}
        {
          descriptionOpen && submenu.description !== '' &&
          <S.DescriptionLinkDropdownDasa>
            {submenu.description}
          </S.DescriptionLinkDropdownDasa>

        }
      </S.ItemLinkDropdownDasa>


      {hasSubmenu && submenuOpen ? <VerticalSubmenu submenu={submenu.submenus} /> : null}

    </S.SubMenuItemsDropdownDasa>
  )
}

const VerticalSubMenuDasa = ({ submenus }) => {
  return (
    <S.DropdownVertical data-testid="dropdown-menu-open" className='menuDropdown'>
      {submenus.map(submenu => (
        <SubmenuItem submenu={submenu} key={submenu.title} />
      ))}
    </S.DropdownVertical>
  )
}

const submenuType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  submenus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      submenus: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
})

VerticalSubMenuDasa.propTypes = {
  submenus: PropTypes.arrayOf(submenuType),
}

SubmenuItem.propTypes = {
  submenu: submenuType,
}

export default VerticalSubMenuDasa